@import url("https://fonts.googleapis.com/css?family=Nunito:400,700|Raleway:400,700");
@import url("https://fonts.googleapis.com/css?family=Waiting+for+the+Sunrise");
body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
  overflow-x: hidden;
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: #1F325D;
}

hr.light {
  border-color: #fff;
}

a {
  color: #1F325D;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  color: #1f325d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', 'Helvetica Neue', Arial, sans-serif;
}

.bg-primary {
  background-color: #1F325D !important;
}

.bg-light {
  background-color: #E8EAEE !important;
}

.border-light {
  border-color: #E8EAEE !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.text-orange {
  color: #E5432E;
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

.text-shadow {
  text-shadow: 0 2px 4px black;
}

.text-handwritten {
  font-family: 'Waiting for the Sunrise', 'Nunito', Arial, sans-serif;
}

.drop-shadow {
  box-shadow: 0 2px 4px rgba(31, 50, 93, 0.2);
}

/*button class helpers in _bootstrap-ovverrides.scss*/
section {
  padding: 5rem 0;
}

footer {
  padding-top: 4rem;
}

footer h5 {
  font-size: 14px;
  text-transform: uppercase;
}

footer h4 a {
  color: white;
  /*ipad turns #s into links*/
}

footer ul {
  -webkit-margin-before: 0px;
  -webkit-padding-start: 0px;
}

footer ul.company-brands li img {
  margin: 10px 0;
}

footer ul li {
  list-style-type: none;
  line-height: 2.5;
}

footer ul li a:hover {
  color: #fff !important;
}

.table tr.active {
  background: #E8EAEE;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.section-heading {
  margin-top: 0;
  font-weight: 700;
  color: #1F325D;
}

::-moz-selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

::selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

img::selection {
  color: #fff;
  background: transparent;
}

img::-moz-selection {
  color: #fff;
  background: transparent;
}

#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #fff;
  line-height: 1;
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#mainNav .navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
  color: #1F325D;
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
}

#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #1f325d;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #212529;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
  color: #1F325D;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active-nav,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active-nav {
  color: #F9C631 !important;
  border-bottom: 3px solid #F9C631;
  background-color: transparent;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active-nav:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active-nav:hover {
  background-color: transparent;
}

#mainNav button.navbar-toggler.navbar-toggler-right {
  float: right;
  /*fix left side positioning on ipad/mobile */
}

@media (min-width: 992px) {
  #mainNav {
    border-color: #1F325D;
    background-color: #1F325D;
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: #fff;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    padding: 0.5rem 1rem;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
    color: #fff;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: #F9C631;
  }
  #mainNav.navbar-shrink, #mainNav.site-page {
    border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    background-color: #fff;
  }
  #mainNav.navbar-shrink .navbar-brand, #mainNav.site-page .navbar-brand {
    color: #1F325D;
  }
  #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover, #mainNav.site-page .navbar-brand:focus, #mainNav.site-page .navbar-brand:hover {
    color: #1f325d;
  }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus, #mainNav.site-page .navbar-nav > li.nav-item > a.nav-link,
  #mainNav.site-page .navbar-nav > li.nav-item > a.nav-link:focus {
    color: #212529;
  }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus:hover, #mainNav.site-page .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav.site-page .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: #1F325D;
  }
}

header.masthead {
  padding-top: 8rem;
  padding-bottom: calc(8rem - 56px);
  background-image: url("/img/header.jpg");
  background-position: 60% center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

header.masthead hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

header.masthead h1 {
  font-size: 2rem;
}

header.masthead p {
  font-family: 'Raleway', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 1.75rem;
}

header.masthead .my-logo {
  position: absolute;
  top: 75px;
  left: .75em;
  height: 60px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

header.masthead .my-logo.move-to-nav {
  position: fixed;
  top: 10px;
  z-index: 1050;
}

header.masthead .my-logo.move-to-nav img {
  height: 30px;
}

header.masthead .overlay-box {
  background: rgba(0, 0, 0, 0.4);
  margin-top: 9rem;
}

header.masthead .overlay-box p {
  line-height: 1.25;
}

header.masthead .overlay-box :after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: -200px;
  width: 200px;
  background: rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  header.masthead .overlay-box :after {
    content: none;
    display: none;
  }
}

@media (max-width: 600px) {
  header.masthead .container.my-auto {
    margin-bottom: -72px !important;
  }
  header.masthead .my-logo {
    left: 15px;
  }
}

@media (max-width: 991px) {
  header.masthead .my-logo {
    position: fixed;
    top: 10px;
    z-index: 1050;
  }
  header.masthead .my-logo img {
    height: 30px;
  }
}

header.masthead.about-page {
  background-image: url("/img/brooke-cagle-195777.png");
}

header.masthead.careers-page {
  background-image: url("/img/careers-header.png");
}

header.masthead.contact-page {
  background-image: url("/img/contact-header.png");
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 56px);
  }
  header.masthead p {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  header.masthead {
    height: 45vh;
    min-height: 425px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 4rem;
  }
}

.service-box {
  max-width: 400px;
}

#mission .brandlogos .row > div {
  padding-bottom: 1rem;
}

#mission .brandlogos .card {
  background-size: cover;
  padding-top: 1rem;
  height: 100%;
}

#mission .brandlogos .card .card-img {
  max-width: 90%;
  margin: 1rem auto 0;
}

#mission .brandlogos .card .card-body {
  padding: .5rem 1rem 1rem;
}

#mission .brandlogos .card .card-body p {
  font-size: 14px;
}

#mission .brandlogos .card.ce-card {
  background-image: url("/img/brandlogos/ce-card.jpg");
}

#mission .brandlogos .card.mai-card {
  background-image: url("/img/brandlogos/mai-card.jpg");
  background-position: 40% 35%;
}

#mission .brandlogos .card.mai-card .card-img {
  max-height: 50px;
  max-width: 177px;
}

#mission .brandlogos .card.cego-card {
  background-image: url("/img/brandlogos/cego-card.jpg");
}

#mission .brandlogos .card.cego-card .card-img {
  max-height: 50px;
  max-width: 177px;
}

#mission .brandlogos .card.acce-card {
  background-image: url("/img/brandlogos/acce-card.jpg");
}

@media (min-width: 768px) {
  #mission .brandlogos .card.ce-card .card-img {
    height: 30px;
    width: 385px;
  }
  #mission .brandlogos .card.sa-card .card-img {
    height: 27px;
    width: 281px;
  }
  #mission .brandlogos .card.cego-card .card-img {
    height: 50px;
    width: 177px;
  }
  #mission .brandlogos .card.acce-card .card-img {
    height: 26px;
    width: 385px;
  }
}

#mission #brand-infographic hr, #mission #brand-infographic .circle {
  display: none;
}

#mission #brand-infographic .about-box {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#mission #brand-infographic img {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 768px) {
  #mission #brand-infographic .img-brand-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #E8EAEE;
    text-align: center !important;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
  }
  #mission #brand-infographic .img-brand-container.move-left img {
    margin-left: -60%;
  }
  #mission #brand-infographic .img-brand-container.move-left a.btn {
    transform: translateX(50%);
    display: inline-block;
  }
  #mission #brand-infographic .img-brand-container img {
    pointer-events: none;
  }
  #mission #brand-infographic .img-brand-container a.btn {
    position: absolute;
    display: none;
    transform: translateX(400%);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
  }
  #mission #brand-infographic .about-box {
    display: none !important;
  }
}

@media (min-width: 768px) {
  #mission #brand-infographic a[role="button"] {
    display: none;
  }
  #mission #brand-infographic hr {
    display: block;
    border-width: 5px;
    max-width: none !important;
    width: 100%;
    border-color: #E8EAEE;
  }
  #mission #brand-infographic img.img-fluid {
    max-width: 95%;
  }
  #mission #brand-infographic img.img-fluid.cecredit {
    margin-bottom: -12px !important;
    margin-left: -37px !important;
  }
  #mission #brand-infographic img.img-fluid.addictionce {
    max-width: 140%;
    margin-left: -90px;
  }
  #mission #brand-infographic img.img-fluid.hovered {
    transform: scale(1.3);
  }
  #mission #brand-infographic #bottom-brands .img-brand-container {
    margin-top: 1.5rem !important;
  }
  #mission #brand-infographic #bottom-brands .img-brand-container a.button {
    position: absolute;
  }
  #mission #brand-infographic #bottom-brands .brand-bottom {
    margin-left: 8rem;
  }
  #mission #brand-infographic #top-brands a.button {
    position: absolute;
  }
  #mission #brand-infographic #top-brands .img-brand-container {
    margin-bottom: 1.5rem !important;
  }
  #mission #brand-infographic .circle-span {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
  }
  #mission #brand-infographic .circle-span .circle {
    width: 34px;
    height: 34px;
    background: #fff;
    border: 6px solid #E8EAEE;
    border-radius: 50%;
    display: inline-block;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
  }
  #mission #brand-infographic .circle-span .circle.hovered {
    background: #E8EAEE !important;
  }
  #mission #brand-infographic .about-box {
    position: absolute;
    text-align: left;
    background: #fff;
    border: 5px solid #E8EAEE;
    width: 200%;
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  #mission #brand-infographic .about-box.top {
    top: 30%;
  }
  #mission #brand-infographic .about-box.bottom {
    bottom: 52%;
  }
  #mission #brand-infographic .about-box h2 {
    color: #E5432E;
    font-size: 1.25em;
    line-height: 1.5em;
  }
  #mission #brand-infographic .about-box p {
    margin-bottom: 0px;
  }
}

#mission p {
  font-size: 16px;
}

#numbers .numbers-icon {
  color: #E5C2BD;
  position: absolute;
  left: 50%;
  transform: translate(-50%) scale(2.5) !important;
  z-index: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#numbers h1 {
  font-weight: bold;
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
  position: relative;
  background: -webkit-linear-gradient(-90deg, #E5432E, #E5C2BD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#numbers h5 {
  color: #1F325D;
  text-transform: uppercase;
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
  position: relative;
}

@media (max-width: 768px) {
  #numbers .service-box {
    margin: 4rem auto;
  }
}

@media (min-width: 992px) {
  #numbers h1 {
    font-weight: bold;
    font-size: 3.5rem;
  }
}

.img-partner {
  width: 80% !important;
  margin: 0 auto;
}

blockquote {
  margin: 1.5rem;
}

blockquote p {
  font-size: 1rem;
}

blockquote cite {
  float: right;
  color: #1F325D;
  font-family: 'Raleway', 'Helvetica Neue', Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

blockquote:before {
  content: "\201C";
  color: #1F325D;
  font-size: 4em;
  position: absolute;
  left: 5px;
  top: -20px;
}

.quote-img {
  background-image: url("/img/smiling-people.jpg");
  background-position: top center;
  min-height: 200px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 767px) {
  #mission .brandlogos .card {
    max-width: 100%;
  }
}

.about-quote-box {
  background: rgba(0, 0, 0, 0.4);
}

.about-quote-box p {
  margin: 0 auto;
}

.team {
  padding: 5em 0 0;
}

.team h2.names, .team h3, .team h4, .team h5 {
  margin-bottom: 0px;
}

.team .people figure {
  flex: 0 0 30%;
}

.team .people img {
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 0px;
  display: block;
}

@media (max-width: 1023px) {
  .team .people img {
    max-width: 120px;
  }
}

@media (min-width: 1024px) {
  .team {
    /*		.arrow1:after {
		position: absolute;
		bottom:-70px;
		left:30px;
		display: block;
  		content:'';
  		background-image: url('/img/arrow1.svg');
  		background-size: 40px 90px;
  		height: 90px;
  		width: 40px;
		}
		.arrow2:after {
		position: absolute;
		bottom:-95px;
		right:30px;
		display: block;
  		content:'';
  		background-image: url('/img/arrow2.svg');
  		background-size: 54px 99px;
  		height: 99px;
  		width: 54px;
		}
		.arrow2b:after {
			position: absolute;
			top:20px;
			right:-40px;
			display: block;
  			content:'';
  			background-image: url('/img/arrow2.svg');
  			background-size: 54px 101px;
  			height: 101px;
  			width: 54px;
  			!*rotate() is not compatible in all browsers.
  		 	Replace soiurce svg on approval*!
  			transform: rotate(-20deg);
		}
		.arrow3:after {
			position: absolute;
			top:20px;
			left:-60px;
			display: block;
  			content:'';
  			background-image: url('/img/arrow3.svg');
  			background-size: 108px 228px;
  			height: 228px;
  			width: 108px;
  			!*rotate() is not compatible in all browsers.
	  		Replace soiurce svg on approval*!
	  		transform: rotate(30deg);
		}*/
  }
  .team .people img {
    max-width: 200px;
  }
}

.testimonials p {
  font-size: 18px;
  margin-bottom: .25rem;
}

.testimonials img {
  height: auto;
  max-width: 100%;
}

.testimonials img.small-logo {
  max-height: 2.5rem;
  max-width: 90%;
}

.testimonials hr {
  max-width: 90%;
}

.testimonials blockquote:before {
  content: "";
  display: none;
}

.testimonials blockquote footer {
  padding-top: 0px;
  font-weight: bold;
  font-style: italic;
}

.advisory-team {
  padding-bottom: 5em;
}

.careers-page .table tr > td {
  border-top: none;
  padding: 1.5rem;
}

.careers-page h5 {
  margin-bottom: 0px;
  font-weight: bold;
}

.text-primary {
  color: #1F325D !important;
}

.btn {
  font-weight: 400;
  border: none;
  border-radius: 2px;
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: #1F325D;
  border-color: #1F325D;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff;
  background-color: #19284a !important;
}

.btn-primary:active, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}

.btn-white {
  color: #1F325D;
  background-color: #fff;
  border-color: #1F325D;
  border: 1px solid;
}

.btn-white:hover, .btn-white:focus, .btn-white:active,
.active .btn-white {
  color: #fff !important;
  background-color: #1F325D;
}

.btn-white:active, .btn-white:focus,
.active .btn-white {
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}

.btn-orange {
  background-color: #E5432E;
  color: #fff;
  border: 1px solid #E5432E;
}

.btn-orange:hover, .btn-orange:focus, .btn-orange:active {
  color: #fff;
  border-color: #fff;
  background-color: #de321c !important;
}

.btn-orange:active, .btn-orange:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}
